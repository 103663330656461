import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { APIRequestResources } from "../enums";
import { APIRequest } from "../classes";
import { SignInSession, UserCredential } from "../types/auth.type";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends APIRequest {
  private readonly USERNAME_KEY = 'username';
  private readonly USERNAME_EXPIRY_KEY = 'username_expiry';
  private readonly EXPIRY_DAYS = 3;

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.AuthService);
  }

  private setUsernameWithExpiry(username: string | null | undefined): void {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + this.EXPIRY_DAYS * 24 * 60 * 60 * 1000);
    if (typeof username === "string") {
      localStorage.setItem(this.USERNAME_KEY, username);
    }
    localStorage.setItem(this.USERNAME_EXPIRY_KEY, expiryDate.toISOString());
  }

  private isUsernameExpired(): boolean {
    const expiryDate = localStorage.getItem(this.USERNAME_EXPIRY_KEY);
    if (!expiryDate) return true;
    return new Date() > new Date(expiryDate);
  }

  signIn = (userCredential: UserCredential) => {
    this.post<SignInSession>(userCredential, { endpoint: `login` }).subscribe({
      next: (res) => {
        if (res.data) {
          this.setUsernameWithExpiry(userCredential.username);

          sessionStorage.setItem("user-auth", JSON.stringify(res.data));

          this.router.navigate(['/dashboard']);
        }
        console.log('Sign in successful:', res);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  validate = () => {
    return this.get({
      endpoint: `token`,
    });
  }

  isUserExist(): boolean {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU";
    return userSessionData != "NAU";
  }

  getAuthToken(): string {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU";
    if (userSessionData === "NAU") { return ""; }
    let userData: SignInSession = JSON.parse(userSessionData);
    return <string>userData.accessToken;
  }

  getUserName(): string {
    if (this.isUsernameExpired()) {
      localStorage.removeItem(this.USERNAME_KEY);
      localStorage.removeItem(this.USERNAME_EXPIRY_KEY);
      return "";
    }
    return localStorage.getItem(this.USERNAME_KEY) || "";
  }

  userSignOut() {
    sessionStorage.removeItem("user-auth");
    this.router.navigate(['/login']);
  }
}
