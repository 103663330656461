<div class="main">
<div class="bg-gray-50 min-h-screen">
  <nav class="bg-white border-gray-200">

    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <img src="/./assets/maxim-apps/maxim-logo.svg" class="h-10" alt="Maxim Logo" />
      <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">

        <button type="button" class="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300" id="user-menu-button"
                aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
          <span class="sr-only">Open user menu</span>
          <img class="w-8 h-8 bg-white rounded-full" src="/./assets/maxim-apps/user.svg" alt="user photo">
        </button>

        <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow" id="user-dropdown">
          <div class="px-4 py-3">
            <span class="block text-sm cursor-default text-gray-900">{{ userAuthData?.name }}</span>
          </div>
          <ul class="py-2" aria-labelledby="user-menu-button">
            <li>
              <a class="block cursor-not-allowed px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">Settings</a>
            </li>
            <li>
              <a (click)="logout()" class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign out</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </nav>


  <div class="p-6 mx-auto max-w-7xl pt-24">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      @if (userAuthData && userAuthData.applications) {
        @for (item of userAuthData.applications; track item; let i = $index) {
          <div
            class="bg-white transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl rounded-xl shadow-lg overflow-hidden"
            [ngClass]="getBackgroundColor(i)"
          >
            <div class="p-6 flex flex-col h-full">
              <div class="flex justify-between items-start mb-4">
                <h3 class="text-lg font-semibold text-gray-900 ">{{ item.name }}</h3>
                <span
                  class="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-200 rounded-full">{{ item.description || 'Uncategorized' }}</span>
              </div>
              <p
                class="text-sm text-gray-600 mb-6 flex-grow">{{ item.category || 'No application description available' }}</p>
              <div class="flex justify-between items-center">
                <button (click)="handleAppRedirect(item.applicationUrl)"
                        class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center"
                >
                  <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  Launch App
                </button>
              </div>
            </div>
          </div>
        }
      }
    </div>
  </div>
</div>
</div>


